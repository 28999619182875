import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { SWRConfig } from "swr";

import Container from "./components/atoms/Container";
import PreLoader from "./components/atoms/PreLoader";

import GlobalStyles from "./themes/global";

import { ThemeProvider } from "./contexts/ThemeContext";
import { ChatProvider } from "./contexts/ChatContext";

import ScrollToTop from "./utils/ScrollToTop";
import SWROptions from "./utils/swr";

// import AuthRoute from "./routes/AuthRoute";
// import DashboardRoute from "./routes/DashboardRoute";
// import RestrictionRoute from "./routes/RestrictionRoute";

const AuthRoute = lazy(() => import("./routes/AuthRoute"));
const DashboardRoute = lazy(() => import("./routes/DashboardRoute"));
const RestrictionRoute = lazy(() => import("./routes/RestrictionRoute"));

export default function App() {
  return (
    <ThemeProvider>
      <SWRConfig value={SWROptions}>
        <ChatProvider>
          <Router>
            <ScrollToTop />
            <GlobalStyles />
            <Suspense fallback={<PreLoader />}>
              <Switch>
                <Route path="/dashboard">
                  <DashboardRoute />
                </Route>
                <Route path="/account">
                  <AuthRoute />
                </Route>
                <Route path="/confirmation">
                  <RestrictionRoute />
                </Route>
                <Route>
                  <Redirect to="/dashboard" />
                </Route>
              </Switch>
            </Suspense>
            <Container
              w="auto"
              h="auto"
              position="absolute"
              bottom="24px"
              right="24px"
              z="10"
              bg="bgContrast"
              p="12px"
              radius="12px"
              border="1px solid"
              bordercolor="bg"
            >
              <div id="google_translate_element"></div>
            </Container>
          </Router>
        </ChatProvider>
      </SWRConfig>
    </ThemeProvider>
  );
}
